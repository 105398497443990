<template>
  <div class="stock-market">
    <div class="stock-market__wrapper" :style="{ width: isMobile ? '80%' : isTablet ? '80%' : '40%' }">
      <h1 class="h1">
        Can <span style="font-weight: bold">Behavioural Forecast</span> be
        applied to the stock market?
      </h1>
      <div style="margin-top: 4rem">
        <p>
          In this research, we trained a DFN-3 model with Open and Close prices
          of 3482 NASDAQ stocks, using data from 2018 up to today. The goal was
          to find if there are temporal micro-paterns that can help predict with
          high accuracy the prices of any stock, over the nex 30 days.
        </p>
      </div>
      <div style="margin-top: 4rem">
        <form class="newsletter__form" @submit.prevent="submitNewsletter">
      <label class="newsletter__label hidden" for="email_request">Email:</label>
      <input
        class="newsletter__input"
        style="width: 100%"
        type="email"
        v-model="email"
        placeholder="Email"
      />
      <button class="newsletter__button" id="newsletter_btn" type="submit" :style="{ 'margin-left': isMobile ? '-124px' : '' }">
        <p v-if="isMobile">Newsletter</p>
        <p v-else>Newsletter with monthly predictions </p>
      </button>
    </form>
      </div>
      <button
        class="stock-market__button"
        :style="{ width: isMobile ? '' : '80%' }"
        style="pointer-events: auto;"
        @click="goToDeveloperPlatform"
      >
        Enter Predictive Platform
      </button>
    </div>
    
  </div>
    <div id="snackbar"></div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: null,
      isMobile: false,
      isTablet: false,
    };
  },
  methods: {
    goToDeveloperPlatform(){
      window.location.assign("https://dfn.assetfloow.com");
    },
  showSnackbarSuccess(text) {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "✔ " + text;
      snackbar.style.background = "#7da53a";
      snackbar.style.left = this.isMobile ? "4%" : "38%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
    showSnackbarError(error) {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "✗ " + error;
      snackbar.style.background = "#962929";
      snackbar.style.left = this.isMobile ? "4%" : "38%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
  requiredFields() {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "Please insert the email.";
      snackbar.style.left = this.isMobile ? "4%" : "38%";
      snackbar.style.background = "#e5ab46";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
    clearFields() {
      this.email = "";
    },
    submitNewsletter(){
      if (!this.email) {
        this.requiredFields();
        return;
      }

      let url = "https://api-brands.assetfloow.com/api/v1/join-waitlist/";

      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: url,
        data: {
          email: this.email,
        },
      })
        .then(function (response) {
          if (response.status != 200 && response.status != 201) {
            this.showSnackbarError(
              "An error has occurred. please try again later."
            );
          } else {
            return response;
          }
        })
        .then(() => {
          this.clearFields();
          this.showSnackbarSuccess("You successfully joined the waitlist.");
        })
        .catch(() => {
          this.showSnackbarError(
            "An error has occurred. please try again later."
          );
        });
    },
    checkDevice() {
      this.isMobile = window.innerWidth <= 768;
      this.isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
    },
  },
  created() {
    this.checkDevice();
    window.addEventListener("resize", this.checkDevice);
  },
  mounted() {
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>

<style scoped lang="scss">
@import "../styles/styles.scss";

.stock-market {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(295deg, rgba(99,89,186,1) 0%, rgba(227,100,199,1) 100%);
  height: 100vh;

  &__button {
    @include button(fit-content, fit-content);

    position: relative;
    background: $button-background-2;
    border: none;
    box-shadow: none;
    color: $white-color;
    z-index: 20;
    margin-top: 2rem;
  }

  &__wrapper {
    text-align: center;
    color: white;
  }
}

.h1 {
  font-size: 37px;
  font-weight: normal;
}


.p {
  font-size: 14px;
  font-style: italic;
}

.newsletter {
  &__form {
    display: flex;
    justify-content: center;
  }

  &__input[type="email"] {
    position: relative;
    padding: 1rem;
    border-radius: 2rem;
    border: none;
    margin-bottom: 1rem;
    z-index: 20;
  }

  &__button {
    @include button(fit-content, fit-content);

    margin-left: -300px;
    margin-bottom: 20px;
    margin-top: 4px;
    position: relative;
    background: $button-background-2;
    border: none;
    box-shadow: none;
    color: $white-color;
    z-index: 20;
  }
}


#snackbar {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 350px;
  /* Set a default minimum width */

  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 8px;
  /* Rounded borders */
  padding: 0.5rem 1.5rem;
  /* Padding */
  position: fixed;
  /* Sit on top of the screen */
  z-index: 22;
  /* Add a z-index if needed */
  left: 50%;
  /* Center the snackbar */
  bottom: 30px;
  /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
</style>
